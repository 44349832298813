require("jquery");
require("parsleyjs");
require("parsleyjs/dist/i18n/de");
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("trix");
require("@rails/actiontext");
require("bootstrap");
require("@fortawesome/fontawesome-pro");

window.Parsley.setLocale('de');

import '../stylesheets/app';

require('../bundles/datetimepicker');
require('../bundles/cocoon');
require('../bundles/tooltip');

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

const images = require.context("../images", true);
const imagePath = name => images(name, true);
