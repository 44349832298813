require("moment");
// Hier wird minified required, weil babel (?) mit der normalen Datei Mist macht
require("tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4.min.js");

$(function () {
  $("*[data-toggle='datetimepicker']").datetimepicker({
    locale: "de",
    format: 'L'
  });
})
